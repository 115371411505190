.notifications {
    .notification {
        span {
            margin-right: 5px;
        }
    }

    .ant-timeline-item {
        padding-bottom: 5px;
    }
}
